// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-beelzebab-js": () => import("./../src/pages/projects/beelzebab.js" /* webpackChunkName: "component---src-pages-projects-beelzebab-js" */),
  "component---src-pages-projects-diskalendar-js": () => import("./../src/pages/projects/diskalendar.js" /* webpackChunkName: "component---src-pages-projects-diskalendar-js" */),
  "component---src-pages-projects-quickstock-js": () => import("./../src/pages/projects/quickstock.js" /* webpackChunkName: "component---src-pages-projects-quickstock-js" */)
}

