module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"df67f5ec-fced-4511-84ba-2d35e033c101","server":"https://ackee-liamhawks-dev.herokuapp.com/","ignoreLocalhost":true,"detailed":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Liam Hawks JS Dev","short_name":"liamhawks.dev","start_url":"/","background_color":"#22577a","theme_color":"#57cc99","display":"minimal-ui","icon":"src/images/LiamHawksIcon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a5a83dbaa820dc8e026504be32c6f676"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
